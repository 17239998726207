/* eslint-disable */
import { base, baseXlsx } from '@/utils/api'

const ENDPOINT = '/financial'

export default {
    getCardFlags(clinicId) {
        const config = {
            params: {}
        };
        return base.get(`${ENDPOINT}/clinic/${clinicId}/cardFlags`, config);
    },
    createCardFlag(data) {
        return base.post(`${ENDPOINT}/cardFlags`, data)
    },
    updateCardFlag(id, data) {
        return base.put(`${ENDPOINT}/cardFlags/${id}`, data)
    },
    deleteCardFlag(id) {
        return base.delete(`${ENDPOINT}/cardFlags/${id}`)
    },

    getCardMachines(clinicId) {
        const config = {
            params: {}
        };
        return base.get(`${ENDPOINT}/clinic/${clinicId}/cardMachines`, config);
    },
    createCardMachine(data) {
        return base.post(`${ENDPOINT}/cardMachines`, data)
    },
    updateCardMachine(id, data) {
        return base.put(`${ENDPOINT}/cardMachines/${id}`, data)
    },
    deleteCardMachine(id) {
        return base.delete(`${ENDPOINT}/cardMachines/${id}`)
    },

    getPaymentMethods(clinicId) {
        const config = {
            params: { clinicId }
        };
        return base.get(`${ENDPOINT}/paymentMethods`, config);
    },
    createPaymentMethod(data) {
        return base.post(`${ENDPOINT}/paymentMethods`, data)
    },
    updatePaymentMethod(id, data) {
        return base.put(`${ENDPOINT}/paymentMethods/${id}`, data)
    },
    deletePaymentMethod(id) {
        return base.delete(`${ENDPOINT}/paymentMethods/${id}`)
    },

    getInstallments(clinicId, page, filters ) {
        const config = {
          params: { clinicId, page, ...filters }
        }
        return base.get(`financial/installments`, config);
    },

    getCategories(clinicId, type, showRemoved, allCategories = false){
      const config = {
        params: { type, showRemoved, allCategories }
      };
      return base.get(`financial/list_categories/${clinicId}`, config);
    },

    getCostCenters(clinicId) {
        return base.get(`financial/costCenters/${clinicId}`);
    },

    getPaymentMethodsBillToReceive(clinicId) {
        return base.get(`financial/get_payment_methods/${clinicId}`);
    },

    getBankAccountBillToReceive(clinicId) {
        return base.get(`financial/get_bank_account/${clinicId}`);
    },

    getBillsExport( clinicId, filters) {
        const config = {
          params: { clinicId, ...filters }
        }
        return baseXlsx.get(`financial/billsExport`, config);
    },

    getCompleteBillsExport( clinicId, filters) {
        const config = {
          params: { clinicId, ...filters }
        }
        return baseXlsx.get(`financial/completeExport`, config);
    },

    getBankAccounts(clinicId) {
        return base.get(`financial/get_bank_account/${clinicId}`);
    },

    // Movements
    getFinancialMovements(clinicId, page, filters) {
        const config = {
            params: { clinicId, page, ...filters }
        }
        return base.get(`financial/clinic/${clinicId}/movements`, config);
    },
    createMovement(data) {
        return base.post(`financial/movements`, data)
    },
    updateMovement(id, data) {
        return base.put(`financial/movements/${id}`, data)
    },
    getMovementsCount(bankAccountIds) {
        const config = {
            params: { bank_account_ids: bankAccountIds }
        }
        return base.get(`financial/movements-count`, config);
    },
    getMovementsExport(clinicId, filters) {
        const config = {
          params: { clinicId, ...filters }
        }
        return baseXlsx.get(`financial/movementsExport`, config);
    },

    //CashFlow
    getCashFlowResume(clinicId, bankAccountId, month, year) {
        const config = {
            params: { clinicId, bankAccountId, month, year }
        }
        return base.get(`financial/cash-flow-resume`, config);
    },
    getCashFlow(clinicId, bankAccountIds, dateStart, dateEnd) {
        const config = {
            params: { clinicId, bankAccountIds, dateStart, dateEnd }
        };
        return base.get(`financial/cashFlow`, config);
    },
    getCashFlowIInstallments(clinicId, bankAccountIds, date) {
        const config = {
            params: { clinicId, bankAccountIds, date}
        };
        return base.get(`financial/cash-flow/installments`, config);
    },
    getCashFlowExport(clinicId, bankAccountIds, dateStart, dateEnd) {
        const config = {
            params: { clinicId, bankAccountIds, dateStart, dateEnd }
        }
        return baseXlsx.get(`financial/cash-flow-export`, config);
    },
    getCashFlowChart(clinicId, bankAccountIds, dateStart, dateEnd) {
        const config = {
            params: { clinicId, bankAccountIds, dateStart, dateEnd }
        }
        return base.get(`financial/cash-flow-chart`, config);
    },
    findCategoryByName(name, clinicId = null) {
        const config = {
            params: { name, clinicId }
        }
        return base.get(`financial/category/find-by-name`, config);
    },
    getCreditPatient(clinicId, patientId, categoryId) {
        const config = {
          clinicId, patientId, categoryId
        }
   
        return base.get(`financial/patientCredit`, {params: config})
    },
    getCreditPatientCategory(clinicId) {
      return base.get(`financial/${clinicId}/getCreditPatientCategory`);
    },
    getBills(page, filters) {
        const config = {
            params: { page, ...filters }
        }
        return base.get(`financial/bills`, config);
    },

    storeBillAttachment(data) {
        return base.post(`financial/attachment`, data)
    },

    updateBillAttachment(id, data) {
        return base.put(`financial/attachment/${id}`, data)
    },

    deleteBillAttachment(id) {
        return base.delete(`financial/attachment/${id}`)
    },

    getCreditResume(data) {
        const config = {
            params: data
        }
        return base.get(`financial/creditResume`, config)
    }
}